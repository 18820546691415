$token-color-black: #202020;
$token-color-white: #FFFFFF;

$token-color-neutral-20: #d4d4d4;
$token-color-neutral-30: #B2B2B2;
$token-color-neutral-50: #666666;
$token-color-neutral-80: #444444;
$token-color-neutral-100: #000000;
$token-color-neutral-05: #F2F2F2;
$token-color-neutral-00: #FFFFFF;

$token-color-primary-10: #f6f3f9;
$token-color-primary-30: #d6c6f0;
$token-color-primary-50: #B99EE6;
$token-color-primary-contrast-50: $token-color-black;
$token-color-primary-80: #6E37CB;
$token-color-primary-contrast-80: $token-color-white;
$token-color-primary-100: #461E89;
$token-color-primary-contrast-100: $token-color-white;
$token-color-primary-05: #F6F3F9;
$token-color-accent-100:  #D93532;
$token-color-accent-green-100: #B7F0C1;
$token-color-accent-green-200: #698a6f;
$token-color-accent-green-300: #425746;
$token-color-accent-green-contrast-100: $token-color-black;
$token-color-accent-orange-100: #ffcc80;
$token-color-accent-orange-contrast-100: $token-color-black;
$token-color-error: #B51F1A;
$token-color-success: #498424;

$token-color-accent-blue-100: #dfeef7;

$token-size-font-xsmall: 1rem;
$token-size-font-small: 1.2rem;
$token-size-font-medium: 1.6rem;
$token-size-font-large: 2rem;
$token-size-font-xlarge: 3rem;
$token-radius-xsmall: 1px;
$token-radius-small: 2px;
$token-radius-medium: 4px;
$token-radius-large: 8px;
$token-radius-xlarge: 16px;
$token-radius-xxlarge: 32px;
$token-radius-zero: 0px;
$token-spacing-xsmall: 4px;
$token-spacing-small: 8px;
$token-spacing-medium: 12px;
$token-spacing-large: 16px;
$token-spacing-xlarge: 24px;
$token-spacing-xxlarge: 32px;
$token-button-primary-height: 40px;
$token-button-primary-width: 140px;
$token-button-primary-padding: 10px;
$token-button-primary-font-size: 1.6rem;
$token-button-primary-font-weight: bold;
$token-button-primary-text-color: #FFFFFF;
$token-button-primary-text-transform: uppercase;
$token-button-primary-background-color:  $token-color-primary-100;
$token-button-primary-border-radius: 4px;
$token-button-primary-border-color:  $token-color-primary-100;
$token-button-primary-border-width: 2px;
$token-button-primary-hover-text-color: #FFFFFF;
$token-button-primary-hover-background-color: $token-color-primary-50;
$token-button-primary-disabled-text-color: #FFFFFF;
$token-button-primary-disabled-background-color: #B2B2B2;
$token-button-primary-disabled-border-color: #B2B2B2;
$token-button-stroked-height: 40px;
$token-button-stroked-width: 140px;
$token-button-stroked-padding: 10px;
$token-button-stroked-font-size: 1.6rem;
$token-button-stroked-font-weight: bold;
$token-button-stroked-text-color:  $token-color-primary-100;
$token-button-stroked-text-transform: uppercase;
$token-button-stroked-background-color: transparent;
$token-button-stroked-border-radius: 4px;
$token-button-stroked-border-color:  $token-color-primary-100;
$token-button-stroked-border-width: 2px;
$token-button-stroked-hover-text-color:  $token-color-primary-100;
$token-button-stroked-hover-background-color: $token-color-primary-10;
$token-button-stroked-hover-border-color: #C52525;
$token-button-stroked-disabled-text-color: #B2B2B2;
$token-button-stroked-disabled-background-color: #FFFFFF;
$token-button-stroked-disabled-border-color: #B2B2B2;
$token-input-content-font-size: 1.6rem;
$token-input-content-font-weight: normal;
$token-input-content-text-color: #000000;
$token-input-placeholder-text-color: #00151A;
$token-input-label-text-color: #00151A;
$token-input-label-font-size: 1.2rem;
$token-input-error-text-color: #B00020;
$token-input-selected-border-color: #461E89;
$token-input-selected-label-text-color: #461E89;
$token-input-background-color: #FFFFFF;
$token-input-border-radius: 4px;
$token-input-border-color: #B2B2B2;
$token-navigation-top-font-size: 1.2rem;
$token-navigation-top-font-weight: bold;
$token-navigation-top-text-color: #FFFFFF;
$token-navigation-top-background-color: #000000;
$token-tab-font-size: 1.2rem;
$token-tab-font-weight: bold;
$token-tab-active-text-color: #461E89;
$token-tab-active-underline-color: #461E89;
$token-tab-inactive-text-color: #B2B2B2;
$token-tab-inactive-underline-color: #000000;
$token-link-text-color: #6E37CB;
$token-link-font-size: 1.6rem;
$token-link-font-weight: bold;
$token-title-1-text-color: #FFFFFF;
$token-title-1-font-size: 2.4rem;
$token-title-1-font-weight: bold;
